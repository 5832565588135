export const theme = {
  colors: {
    primary: '#41AAE9',
    dark: '#454545',
    black: 'rgba(0, 0, 0, 0.87)',
    darker: '#666',
    navGStart: '#55A2F6',
    navGEnd: '#4EB9F9',
    white: '#ffffff',
    gray: '#f2f5f7',
    green: '#2fc77a',
    lightGray: '#999999',
    grayBlue: '#567894',
    grayDark: '#333333',
    orange: '#fba550',
    red: '#fd5c52',
    purple: '#8f3cf4',
    lightGrayWithOpacity: 'rgba(13, 207, 218, 0.15)',
    grayShadow: 'rgba(72, 107, 125, 0.15)',
    blueShadow: 'rgba(72, 119, 168, 0.5)',
    darkBlueShadow: 'rgba(57, 98, 170, 0.3)'
  },
};

export const endpoints = {
  crewmen: 'api/service/GetCrewmen',
  crewmenByRegion: 'api/service/GetCrewmenByRegion',
  login: '/api/Account/login',
  logout: '/api/Account/logout',
  register: '/api/Account/register',
  me: '/api/Account/Me',
  jobs: '/api/Jobs',
  attachments: '/api/Attachments',
  customerLeads: '/api/CustomerLeads',
  customerClaims: '/api/CustomerClaims',
  crewMoveLogs: '/api/CrewMoveLogs',
  guys: '/api/guys',
  trucks: '/api/trucks',
  ttnUserSettings: '/api/TtnUserSettings',
  closePeriod: '/api/TtnUserSettings/ClosePeriod',
  skipSetup: '/api/TtnUserSettings/SkipSetup',
  logs: '/api/logs',
  saveDaySetup: '/api/daySetup/SaveDaySetup',
  sendEmail: '/api/service/SendConfirmationEmail',
  sendSms: '/api/service/SendSmsToGuys',
  stats: 'api/service/GetStats',
  guysStats: '/api/service/GetGuysStats',
  getSubscription: '/api/Subscription/Get',
  addSubscription: '/api/Subscription/Add',
  deleteSubscription: '/api/Subscription/Delete',
  updateCardDetails: '/api/Subscription/UpdateCard',
  setPassword: 'api/users/SetPassword',
  users: '/api/users',
  blockUser: '/api/users/BlockUser',
  editUserRoles: '/api/users/UpdateUserRoles',
  questionnaires: '/api/Questionnaire/GetAll',
  addQuestionnaire: '/api/Questionnaire/Add',
  updateQuestionnaire: '/api/Questionnaire/Update',
  removeQuestionnaire: '/api/Questionnaire/Remove',
  getQuestionnaire: '/api/Questionnaire/Get',
  makeQuestionnaireAsDefault: '/api/Questionnaire/UpdateDefault',
  addQuestionGroup: '/api/Questionnaire/AddQuestionGroup',
  updateQuestionGroup: '/api/Questionnaire/UpdateQuestionGroup',
  removeQuestionGroup: '/api/Questionnaire/RemoveQuestionGroup',
  addQuestion: '/api/Questionnaire/AddQuestion',
  updateQuestion: '/api/Questionnaire/UpdateQuestion',
  removeQuestion: '/api/Questionnaire/RemoveQuestion',
  createReview: '/api/Review/Send',
  getLogFiles: '/api/logFiles/Get',
  getLogFile: '/api/logFiles/GetFile',
};

export const LOCAL_STORAGE_KEY = 'titan_token';

export const ADD_JOB_ACTION = 'Add Job';
export const UPDATE_JOB_ACTION = 'Update Job';
export const DELETE_JOB_ACTION = 'Delete Job';

export const ACTIONS_ICONS = {
  [ADD_JOB_ACTION]: 'add',
  [UPDATE_JOB_ACTION]: 'loop',
  [DELETE_JOB_ACTION]: 'delete_forever',
};

export const ACTIONS_TEXTS = {
  [ADD_JOB_ACTION]: 'Added',
  [UPDATE_JOB_ACTION]: 'Updated',
  [DELETE_JOB_ACTION]: 'Deleted',
};


export const MODULES = {
  timecards: 'timecards',
  advancedJobModal: 'advancedJobModal'
}

export const FORMS_NAMES = {

  TITAN_JOB_FORM_NAME: 'TitanJob'
}
