import React from "react";
import SwiperSlider from "../SwiperSlider/SwiperSlider";
import {REDIRECT_URL} from "../LandingPage";
import TitanBtn from "../../components/TitanBtn";
import styles from "./BusinessesBanner.module.scss"
import Container from "../Container/Container";

function BusinessesBanner({id}) {
    return (
        <section className={styles.businessesBanner} id={id}>
            <Container>
                <div className={styles.businessesBanner__container}>
                    <div className={styles.businessesBanner__textContainer}>
                        <h2 className={styles.businessesBanner__title}>
                            Take <b>control of your business</b> today
                        </h2>
                        <p className={styles.businessesBanner__text}>
                            Have a 360o View of Your Entire Operation At All Times
                        </p>
                        <TitanBtn
                            large
                            onClick={() => {
                                window.location.href = REDIRECT_URL.registration
                            }}
                        >
                            Get Started Now
                        </TitanBtn>
                    </div>
                    <SwiperSlider/>
                </div>
            </Container>
        </section>
    )
}

export default BusinessesBanner