import React from 'react';
import classNames from 'classnames';
import './TitanAlert.scss';

const TitanAlert = ({ children, className, warning }) =>
  <div className={ classNames({
    'TitanAlert': true,
    'TitanAlert--warning': warning,
    [className]: className
  }) }>
    { children }
  </div>

export default TitanAlert;
