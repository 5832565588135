import React from 'react'
import Container from '../Container/Container';
import styles from './HeroBanner.module.scss'
import HeroImg2x from './group@2x.webp'
import HeroImg from './group.webp'
import HeroImgSmall3x from './group-11@3x.webp'
import TitanBtn from '../../components/TitanBtn';
import {REDIRECT_URL} from '../LandingPage'

function HeroBanner({id}) {
	return (
		<div className={styles.wrap} id={id}>
			<Container>
				<div className={styles.main}>
					<div className={styles.heroText}>
						<div className={styles.heroTextSmall}>Scheduling, Invoicing, Sales</div>
						<h1 className={styles.heroTitle}>Easy Solution
							To Grow <br/>
							Your Business</h1>
						<h4 className={styles.heroSubTitle}>
							Have a 360&#176; View of Your Entire <br/>
							 Operation At All Times
						</h4>
						<TitanBtn
							large
							onClick={(e) => {
									e.preventDefault();
									window.location.href = REDIRECT_URL.registration
							}}

						>Get Started now
						</TitanBtn>
					</div>
					<div className={styles.heroImgWrap}>
						<div className={styles.placeHolder} />
						<picture className={styles.pictureWrap}>
							<source media="(max-width: 1024px)" srcSet={`${HeroImgSmall3x}`} />
							<source media="(min-width: 1025px)" srcSet={`${HeroImg}, ${HeroImg2x} 2x`} />
							<img src={HeroImg} className={styles.heroImg} alt="Manage your business with Clepis, save money and time" />
						</picture>
					</div>

				</div>

			</Container>
		</div>
	)
}

export default HeroBanner