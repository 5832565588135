import React, { useState, useEffect } from 'react';
import styles from './Menu.module.scss'
import classNames from 'classnames';

const MenuItemMobile = ({ itemName, id, active, onCloseModal }) => {

	const [anchorTarget, setAnchorTarget] = useState(null);

	useEffect(() => {
		setAnchorTarget(document.getElementById(id));
	}, [id]);
	const handleClick = event => {
		onCloseModal();
		event.preventDefault();
		anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};
	return (
		<li className={styles.menuItemMobile}>
			<a href={`#${id}`}
			   onClick={handleClick}

			   className={classNames({[styles.menuItemMobileLink]: true, [styles.menuItemMobileLinkActive]: active})}
			   aria-label={`Scroll to ${itemName}`}>
				<span>{itemName}</span>
			</a>
		</li>
	);
};

export default MenuItemMobile;