import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import MenuItem from './MenuItem'
import MenuItemMobile from './MenuItemMobile'
import MenuBg from './MenuBg'
import TitanModal from '../../components/TitanModal'
import styles from './Menu.module.scss'
import Container from '../Container/Container';
import AuthForm from '../../components/AuthForm';
import TitanBtn from '../../components/TitanBtn'
import {REDIRECT_URL} from '../LandingPage'


const Menu = ({config}) => {
	const [modalType, openModal] = useState(false);
	const [activeItem, setActiveItem] = useState(null);
	const [isSticky, setSticky] = useState(false);
	const [mobileMenu, openMobileMenu] = useState(false);
	const menuWrap = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			const curPos = window.scrollY;
			setSticky(curPos >= 40 && !isSticky);
			let curSection = null;

			config.forEach(item => {
				if(curPos >= item.anchorPoint) {
					curSection = item.label;
				}
			});

			setActiveItem(curSection)
		};

		const getAnchorPoints = () => {
			const curScroll = window.scrollY - 100;
			config.forEach(item => {
				item.anchorPoint = document.getElementById(item.id).getBoundingClientRect().top + curScroll
			});

			handleScroll();
		};

		if(!activeItem) {
			setActiveItem(config[0].label);
			const curScroll = window.scrollY - 100;
			config.forEach(item => {
				item.anchorPoint = document.getElementById(item.id).getBoundingClientRect().top + curScroll
			});

			const observer = new MutationObserver(getAnchorPoints);
			observer.observe(document.getElementById('root'), {
				childList: true,
				subtree: true,
			});
			window.addEventListener('scroll', handleScroll);
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	// eslint-disable-next-line
	}, []);


	return (
		<div className={styles.menuWrap} ref={menuWrap}>
			{modalType &&
				<TitanModal isOpen={modalType} onClose={() => openModal(false)}>
					<AuthForm formType={modalType} />
				</TitanModal>
			}
			{mobileMenu &&
				<TitanModal isOpen={mobileMenu} onClose={() => openMobileMenu(false)}>
					<div className={styles.mobileNavWrap}>
						<ul className={styles.navMobileMenu}>
							{
								config.map((item) =>
									<MenuItemMobile
										onCloseModal={() => openMobileMenu(false)}
										itemName={item.label}
										id={item.id}
										key={item.id}
										active={item.label === activeItem}
									/>
								)
							}
						</ul>
						<a href={REDIRECT_URL.login}
						   onClick={
							   (e) => {
								   /*openMobileMenu(true);*/
								   /*openModal(formTypes.logIn);*/
								   e.preventDefault();
								   window.location.href = REDIRECT_URL.login
							   }
						   }
						   className={classNames(styles.menuItemMobileLink)}>Login</a>
						<div>
							<TitanBtn large onClick={
								(e) => {
									e.preventDefault();
									window.location.href = REDIRECT_URL.registration
									//openModal(formTypes.register);
								}
							}>Start free trial</TitanBtn>
						</div>
					</div>
				</TitanModal>
			}
			<MenuBg />
			<Container>
				<div className={styles.mobileMenu}>
					<a href="/" className={styles.mobileLogo}>Logo</a>
					<div className={styles.mobileMenuLeft}>
						<a href={REDIRECT_URL.login}
						   className={styles.mobileNavLink}
						>Login</a>
						<div className={styles.menuToggler}
						     onClick={
							     (e) => {
								     e.preventDefault();
								     //window.location.href = REDIRECT_URL.login
								     openMobileMenu(true);
							     }
						     }
						/>
					</div>
				</div>
				<nav className={styles.nav}>
					<div className={styles.leftSide}>
					{/*	<a href="">Logo</a>*/}
						<ul className={styles.navMenu}>
							{
								config.map((item) =>
									<MenuItem
										itemName={item.label}
										id={item.id}
										key={item.id}
										active={item.label === activeItem}
									/>
								)
							}
						</ul>
					</div>

					<div className={styles.rightMenu}>
						<a href={REDIRECT_URL.login}
						   className={classNames(styles.menuItemLink)}>Login</a>
						<div>
							<TitanBtn onClick={
							(e) => {
								e.preventDefault();
								window.location.href = REDIRECT_URL.registration
								//openModal(formTypes.register);
							}
						}>Start free trial</TitanBtn></div>
					</div>
				</nav>
			</Container>
		</div>

	);
}


export default Menu