import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import styles from './Menu.module.scss'


const MenuBg = () => {
	const [menuBgOpacity, setMenuBgOpacity] = useState(0);
	const [isSticky, setSticky] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const curPos = window.scrollY;
			if(curPos < 140) {
				setMenuBgOpacity((curPos - 40) / 100);
			} else {
				setMenuBgOpacity(100);
			}

			setSticky(curPos >= 40 && !isSticky);

		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};

	}, [isSticky]);


	return (
		<div className={classNames({
			[styles.menuBg]: true,
		})}
		    style={{ opacity: menuBgOpacity}}
		/>

	);
}


export default MenuBg