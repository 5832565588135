import { endpoints, LOCAL_STORAGE_KEY } from '../constants';

export const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
};

export const makeActionCreator = (type) => {
  const wrapper = function (args) {
    const action = { type };
    action.payload = args;
    return action;
  };

  wrapper.toString = () => type;

  return wrapper;
};

function callApi(endpoint, options) {
  const opt = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `bearer ${window.localStorage.getItem(LOCAL_STORAGE_KEY)}`,
      'Content-Type': 'application/json',
    },
  };
  return fetch(endpoint, opt)
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response: { ok, status }, }) => {
      if (!ok) {
        return Promise.reject({ json, status });
      }
      return { json, status };
    })
}

export const loginUserRequest = (creds) => {
  return callApi(endpoints.login, {
    method: 'POST',
    body: JSON.stringify(creds),
  });
}


export const logoutUserRequest = () =>
  callApi(endpoints.logout, {
    method: 'POST',
  });

export const registerUserRequest = (creds) =>
  callApi(endpoints.register, {
    method: 'POST',
    body: JSON.stringify(creds),
  });

export const getLogs = query =>
  callApi(`${endpoints.logs}?${query}`, {
    method: 'GET',
  });
export const getLogFiles = () =>
    callApi(`${endpoints.getLogFiles}`, {
        method: 'GET',
    });

export const getStats = query =>
  callApi(`${endpoints.stats}?${query}`, {
    method: 'GET',
  });

export const getGuysStats = query =>
  callApi(`${endpoints.guysStats}?${query}`, {
    method: 'GET',
  });

export const getGuyStats = (guyId, query) =>
  callApi(`${endpoints.guysStats}/${guyId}?${query}`, {
    method: 'GET',
  });

export const getSubscription = () =>
  callApi(endpoints.getSubscription, {
    method: 'GET',
  });

export const addSubscription = tokenId => {
  const options = tokenId
    ?
    {
        method: 'POST',
        body: JSON.stringify(tokenId)
    }
    :
    { method: 'POST' };

  return callApi(endpoints.addSubscription, options);
};

export const updateCardDetails = tokenId => {
    const options = {
            method: 'POST',
            body: JSON.stringify(tokenId)
        };

    return callApi(endpoints.updateCardDetails, options);
};

export const setUserPassword = (data) =>
  callApi(endpoints.setPassword, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const addUser = (data) =>
  callApi(endpoints.users, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const deleteSubscription = () =>
  callApi(endpoints.deleteSubscription, {
    method: 'DELETE',
  });


export const getMeRequest = () => {
  return fetch(endpoints.me, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      Authorization: `bearer ${window.localStorage.getItem(LOCAL_STORAGE_KEY)}`,
    },
  }).then(response => {
    if (!response.ok) return { json: {}, response };
    return response.json().then(json => ({ json, response }));
  })
    .then(({ json, response: { status }, }) => {
      let ttnUserSettings = {};
      if (status !== 200) return { isAuth: false, ttnUserSettings };

      if (json.ttnUserSettings) {
        ttnUserSettings = json.ttnUserSettings.ttnUserSettings || {};
        ttnUserSettings.yards = json.ttnUserSettings.yards || [];
        ttnUserSettings.regions = json.ttnUserSettings.regions || [];
        ttnUserSettings.guyRoles = json.ttnUserSettings.guyRoles || [];
        ttnUserSettings.leads = json.ttnUserSettings.leads || [];
        ttnUserSettings.insurances = json.ttnUserSettings.insurances || [];
        ttnUserSettings.users = json.users || [];
      }

      return {
        isAuth: true,
        ttnUserSettings,
        roles: json.roles,
        email: json.email,
        isSubscribed: json.isSubscribed
      };
    });
};

export const apiService = {
  loginUserRequest,
  logoutUserRequest,
  registerUserRequest,
  getLogs,
  getStats,
  getGuysStats,
  getMeRequest,
  setUserPassword,
  addUser,
}

