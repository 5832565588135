import React from "react";
import Slider from "react-slick";

import "./slick.scss";
import styles from "./SwiperSlider.module.scss";
import allInCarpets from "./images/all-in-carpets.webp";
import kanvesllc from "./images/kanvesllc.webp";
import musicGroup from "./images/music-group.webp";
import phs from "./images/phs.webp";
import technologyAndCleaning from "./images/technology-and-cleaning.webp";
import zData from "./images/z-data.webp";

function SwiperSlider() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
    };
    return (<div className={styles.slider}>
        <Slider {...settings}>
            <div className="slide-wrap">
                <div className={styles.slider__item}>
                    <div className={styles.slider__photo}>
                        <span className={styles.slider__photo__tmp}>MK</span>
                        <img src={kanvesllc} alt="Jane Tham"/>
                    </div>
                    <div className={styles.slider__text}>
                        <p className={styles.slider__review}>
                            “Clepis helped me minimize the time I needed to send out invoices and was very easy to use.“
                        </p>
                        <p className={styles.slider__post}>
                            <b>Max K | General Manager</b> Kanves Logistics
                        </p>
                    </div>
                </div>
            </div>
            <div className="slide-wrap">
                <div className={styles.slider__item}>
                    <div className={styles.slider__photo}>
                        <span className={styles.slider__photo__tmp}>JT</span>
                        <img src={technologyAndCleaning} alt="Jane Tham"/>
                    </div>
                    <div className={styles.slider__text}>
                        <p className={styles.slider__review}>
                            “This software is convenient, and the support team was very responsive.“
                        </p>
                        <p className={styles.slider__post}>
                            <b>Jane Tham | CEO</b> Technologies & Cleaning LLC
                        </p>
                    </div>
                </div>
            </div>
            <div className="slide-wrap">
                <div className={styles.slider__item}>
                    <div className={styles.slider__photo}>
                        <span className={styles.slider__photo__tmp}>DC</span>
                        <img src={phs} alt="David Chum"/>
                    </div>
                    <div className={styles.slider__text}>
                        <p className={styles.slider__review}>
                            “We started using Clepis about a year ago, and since then, the team has added so many new features and still coming out with constant updates and improvements!”
                        </p>
                        <p className={styles.slider__post}>
                            <b>David Chum | Manager</b> PHS Air Conditioning LLC
                        </p>
                    </div>
                </div>
            </div>
            <div className="slide-wrap">
                <div className={styles.slider__item}>
                    <div className={styles.slider__photo}>
                        <span className={styles.slider__photo__tmp}>MA</span>
                        <img src={zData} alt="Marcus Acosta"/>
                    </div>
                    <div className={styles.slider__text}>
                        <p className={styles.slider__review}>
                            “I liked their feedback tool. Every time I complete a job, it takes me minutes to ask my client for feedback and review.“
                        </p>
                        <p className={styles.slider__post}>
                            <b>Marcus Acosta | VP</b> Zdata Moving
                        </p>
                    </div>
                </div>
            </div>
            <div className="slide-wrap">
                <div className={styles.slider__item}>
                    <div className={styles.slider__photo}>
                        <span className={styles.slider__photo__tmp}>JM</span>
                        <img src={musicGroup} alt="Joe Morales"/>
                    </div>
                    <div className={styles.slider__text}>
                        <p className={styles.slider__review}>
                            “There are still things they can improve, but overall you get a lot of excellent features for a low price.“
                        </p>
                        <p className={styles.slider__post}>
                            <b>Joe Morales | CEO</b> Technologies Universal Music Group
                        </p>
                    </div>
                </div>
            </div>
            <div className="slide-wrap">
                <div className={styles.slider__item}>
                    <div className={styles.slider__photo}>
                        <span className={styles.slider__photo__tmp}>AM</span>
                        <img src={allInCarpets} alt="Alex Martin"/>
                    </div>
                    <div className={styles.slider__text}>
                        <p className={styles.slider__review}>
                            “I used the 30 days trial and got addicted. It saved me sooooo much time, so I just had to sign up.“
                        </p>
                        <p className={styles.slider__post}>
                            <b>Alex Martin | CMO</b> AllIn Carpets
                        </p>
                    </div>
                </div>
            </div>
        </Slider>
    </div>);
}

export default SwiperSlider;