import React from 'react'

export function GoogleIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
			<g fill="none" fillRule="evenodd">
				<g>
					<g>
						<g>
							<g>
								<g>
									<path fill="#4285F4" fillRule="nonzero" d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844c-.209 1.125-.843 2.078-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z" transform="translate(-749.000000, -312.000000) translate(615.000000, 135.000000) translate(100.000000, 162.000000) translate(34.000000, 13.000000) translate(0.000000, 2.000000)"/>
									<path fill="#34A853" fillRule="nonzero" d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332C2.438 15.983 5.482 18 9 18z" transform="translate(-749.000000, -312.000000) translate(615.000000, 135.000000) translate(100.000000, 162.000000) translate(34.000000, 13.000000) translate(0.000000, 2.000000)"/>
									<path fill="#FBBC05" fillRule="nonzero" d="M3.964 10.71c-.18-.54-.282-1.117-.282-1.71s.102-1.17.282-1.71V4.958H.957C.347 6.173 0 7.548 0 9s.348 2.827.957 4.042l3.007-2.332z" transform="translate(-749.000000, -312.000000) translate(615.000000, 135.000000) translate(100.000000, 162.000000) translate(34.000000, 13.000000) translate(0.000000, 2.000000)"/>
									<path fill="#EA4335" fillRule="nonzero" d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0 5.482 0 2.438 2.017.957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" transform="translate(-749.000000, -312.000000) translate(615.000000, 135.000000) translate(100.000000, 162.000000) translate(34.000000, 13.000000) translate(0.000000, 2.000000)"/>
									<path d="M0 0L18 0 18 18 0 18z" transform="translate(-749.000000, -312.000000) translate(615.000000, 135.000000) translate(100.000000, 162.000000) translate(34.000000, 13.000000) translate(0.000000, 2.000000)"/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export function FacebookIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
			<g fill="none" fillRule="evenodd">
				<g fill="#1977F2">
					<g>
						<g>
							<g>
								<path d="M15.363 2H2.637C1.183 2 0 3.183 0 4.637v12.726C0 18.817 1.183 20 2.637 20h5.308v-6.363h-2.11v-3.164h2.11V8.328c0-1.745 1.42-3.164 3.164-3.164h3.2v3.164h-3.2v2.145h3.2l-.528 3.164H11.11V20h4.254C16.817 20 18 18.817 18 17.363V4.637C18 3.183 16.817 2 15.363 2z" transform="translate(-909.000000, -312.000000) translate(615.000000, 135.000000) translate(270.000000, 162.000000) translate(24.000000, 13.000000)"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export function AppleIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
			<g fill="none" fillRule="evenodd">
				<g fill="#333" fillRule="nonzero">
					<g>
						<g>
							<g>
								<path d="M7.358 6.376c.497-.042.868-.102 1.113-.18.385-.133.773-.393 1.165-.78.455-.456.787-.952.997-1.486.21-.535.315-1.027.315-1.477 0-.07-.003-.14-.01-.21-.007-.07-.025-.152-.053-.243-1.252.288-2.15.822-2.692 1.603-.543.78-.82 1.705-.835 2.773zM4.818 20c.364 0 .838-.121 1.422-.364.585-.242 1.097-.364 1.538-.364.441 0 .982.116 1.622.348.64.232 1.142.348 1.506.348.917 0 1.84-.706 2.771-2.12.616-.941 1.057-1.844 1.323-2.71-.637-.189-1.204-.642-1.7-1.36-.497-.716-.746-1.521-.746-2.414 0-.816.235-1.56.704-2.236.258-.372.668-.798 1.228-1.276-.371-.457-.746-.819-1.124-1.086-.671-.464-1.438-.696-2.298-.696-.525 0-1.144.123-1.858.37-.714.245-1.228.368-1.543.368-.245 0-.74-.109-1.486-.326-.745-.218-1.38-.327-1.905-.327-1.231 0-2.251.52-3.06 1.56C.404 8.755 0 10.102 0 11.754c0 1.779.528 3.592 1.585 5.441C2.642 19.065 3.719 20 4.818 20z" transform="translate(-1095.000000, -312.000000) translate(615.000000, 135.000000) translate(440.000000, 162.000000) translate(40.000000, 13.000000)"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export function PlayVideo() {
    return (
        <svg width="148" height="148" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
            <g transform="translate(-913 -1409)" fill="none">
                <path d="M961 1409c-26.496 0-48 21.504-48 48s21.504 48 48 48 48-21.504 48-48-21.504-48-48-48zm0 7.273c22.452 0 40.727 18.275 40.727 40.727 0 22.452-18.275 40.727-40.727 40.727-22.452 0-40.727-18.275-40.727-40.727 0-22.452 18.275-40.727 40.727-40.727zm-7.014 22.036c-.643-.492-1.547-.1-1.693.657l-.02.215v34.185c0 .819.85 1.32 1.536.981l.182-.113 22.575-17.097a1.073 1.073 0 0 0 .167-1.57l-.174-.161-22.573-17.097z"
                      fill="#fff"/>
            </g>
        </svg>
    )
}