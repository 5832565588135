import React from 'react'

export function IconTwitter({color = '#F88547'}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="39" height="32" viewBox="0 0 39 32">
			<g fill="none" fillRule="evenodd">
				<g fill="#F88547">
					<g>
						<path d="M14.55 32c-1.687 0-3.423-.137-5.169-.411-3.076-.484-6.19-2.097-7.07-2.58L0 27.742l2.502-.824c2.734-.902 4.398-1.461 6.457-2.337-2.062-1.001-3.65-2.802-4.416-5.13l-.582-1.771.477.073c-.452-.457-.81-.922-1.086-1.344-.986-1.5-1.507-3.333-1.396-4.904l.11-1.546.923.359c-.39-.738-.671-1.525-.837-2.35-.403-2.013-.066-4.152.95-6.022l.805-1.48L4.982 1.76C8.384 5.858 12.692 8.288 17.807 9c-.21-1.444-.053-2.837.465-4.086.604-1.453 1.677-2.686 3.102-3.565C22.958.373 24.878-.1 26.782.018c2.02.124 3.853.883 5.309 2.195.71-.186 1.235-.383 1.943-.65.426-.161.91-.344 1.514-.553l2.228-.77-1.453 4.16c.096-.007.195-.014.298-.019L39 4.273 37.594 6.2c-.08.11-.101.142-.13.185-.113.171-.254.384-2.184 2.968-.484.647-.725 1.49-.68 2.373.171 3.358-.24 6.396-1.222 9.03-.929 2.49-2.369 4.628-4.278 6.353-2.363 2.133-5.377 3.594-8.957 4.34-1.756.367-3.642.551-5.593.551z" transform="translate(-1142.000000, -8816.000000) translate(1142.000000, 8816.000000)"/>
					</g>
				</g>
			</g>
		</svg>
	)
}

export function IconFaceBook({color = '#F88547'}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
			<g fill="none" fillRule="evenodd">
				<g fill="#F88547">
					<g>
						<path d="M27.313 0H4.688C2.103 0 0 2.103 0 4.688v22.625C0 29.896 2.103 32 4.688 32h9.437V20.687h-3.75v-5.625h3.75V11.25c0-3.102 2.523-5.625 5.625-5.625h5.688v5.625H19.75v3.813h5.688l-.938 5.624h-4.75V32h7.563C29.896 32 32 29.897 32 27.312V4.688C32 2.103 29.897 0 27.312 0z" transform="translate(-1077.000000, -8816.000000) translate(1077.000000, 8816.000000)"/>
					</g>
				</g>
			</g>
		</svg>
	)
}

export function IconInstagram({color = '#F88547'}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
			<g fill="none" fillRule="evenodd">
				<g fill="#F88547">
					<g>
						<path d="M27.313 0H4.688C2.103 0 0 2.103 0 4.688v22.625C0 29.896 2.103 32 4.688 32h22.625C29.896 32 32 29.897 32 27.312V4.688C32 2.103 29.897 0 27.312 0zm-11.25 24.375c-4.653 0-8.438-3.785-8.438-8.438 0-4.652 3.785-8.437 8.438-8.437 4.652 0 8.437 3.785 8.437 8.438 0 4.652-3.785 8.437-8.438 8.437zm9.375-15c-1.551 0-2.813-1.262-2.813-2.813 0-1.55 1.262-2.812 2.813-2.812 1.55 0 2.812 1.262 2.812 2.813 0 1.55-1.262 2.812-2.813 2.812z" transform="translate(-1214.000000, -8816.000000) translate(1214.000000, 8816.000000)"/>
						<path fillRule="nonzero" d="M16.063 10.604c-2.941 0-5.334 2.393-5.334 5.334 0 2.94 2.393 5.333 5.334 5.333 2.94 0 5.333-2.393 5.333-5.334 0-2.94-2.393-5.333-5.334-5.333z" transform="translate(-1214.000000, -8816.000000) translate(1214.000000, 8816.000000)"/>
					</g>
				</g>
			</g>
		</svg>
	)
}


