import React from 'react'
import classNames from 'classnames'
import Container from '../Container/Container'
import styles from './Solution.module.scss'
import calendarImg from './img/calendar@2x.webp'
import jobInfoImg from './img/jobInfo@2x.webp'
import statisticsImg from './img/statistics@2x.webp'
import daySetupImg from './img/day-setup@2x.webp'
import paymentImg from './img/payment@2x.webp'
import TitanBtn from '../../components/TitanBtn';
import ImagePlaceHolder from '../ImagePlaceHolder/ImagePlaceHolder';
import {REDIRECT_URL} from '../LandingPage';

const images = {
	paymentImg: {
		src: paymentImg,
		placeholder: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAA7CAYAAADb7MIAAAAAY0lEQVRoge3PAQ2AMBDAQEDN+zfIVCxLlzsF7Tsz/3OZ73TADqYqTFWYqjBVYarCVIWpClMVpipMVZiqMFVhqsJUhakKUxWmKkxVmKowVWGqwlSFqQpTFaYqTFWYqjBVceXUAmoGAe1a14QaAAAAAElFTkSuQmCC'
	},
	calendarImg: {
		src: calendarImg,
		placeholder: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAA5CAYAAACWJGMLAAAAX0lEQVRoge3PAQ3AMAzAsH78Of8orimVjSB5ZuadZc7tgD+YqjBVYarCVIWpClMVpipMVZiqMFVhqsJUhakKUxWmKkxVmKowVWGqwlSFqQpTFaYqTFWYqjBVYapi5dQH+6ABccoxMAEAAAAASUVORK5CYII='
	},
	jobInfoImg: {
		src: jobInfoImg,
		placeholder: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABVCAYAAAC/xEFcAAAA3klEQVR4nO3RQQ0AIBDAsAP/nuGNAvZoFSzZmpkzZOzfAbwMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpAYQ2IMiTEkxpCYC2CsAanYbmDzAAAAAElFTkSuQmCC'
	},
	statisticsImg: {
		src: statisticsImg,
		placeholder: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAAtCAYAAAAOYyOGAAAAUklEQVRoge3PAQ3AMAzAsH78Of8orimVjSB5ZuadZc7tgD+YqjBVYarCVIWpClMVpipMVZiqMFVhqsJUhakKUxWmKkxVmKowVWGqwlSFqYqVUx8EdQFZ5ZY4tgAAAABJRU5ErkJggg=='
	},
	daySetupImg: {
		src: daySetupImg,
		placeholder: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAAyCAYAAAD845PIAAAAVklEQVRoge3PAQ0AIRDAsOP9ewYV5FOyKtjWzOx5zPd3wA1NKZpSNKVoStGUoilFU4qmFE0pmlI0pWhK0ZSiKUVTiqYUTSmaUjSlaErRlKIpRVOKphQHuPcBYwb7ZDYAAAAASUVORK5CYII='}
};


function Solution({id}) {
	return (
		<section className={styles.wrap} id={id}>
			<Container>
				<div className={classNames(styles.item, styles.itemRightSideRightAligned)}>
					<div className={styles.leftSide}>
						<h3 className={styles.itemTitle}>Schedule Calendar</h3>
						<div className={styles.itemDescription}>
							Personalize your calendar with color coding and filters to quickly see what’s important—like jobs assigned to certain team members, or job status.
						</div>
						<ul className={styles.list}>
							<li className={styles.listItem}>Automatic Reminders</li>
							<li className={styles.listItem}>Quick add or manage jobs</li>
							<li className={styles.listItem}>Custom Made Calendar</li>
							<li className={styles.listItem}>All Device Compatibility</li>
						</ul>
						<TitanBtn large onClick={() => { window.location.href = REDIRECT_URL.registration }}>Try now</TitanBtn>
					</div>

					<div className={classNames(styles.rightSide, styles.rightSideRightAligned)} >
						<ImagePlaceHolder
							placeholder={images.calendarImg.placeholder}
							src={images.calendarImg.src}
							alt="calendar banner"
							className={styles.itemImg}
							style={{maxWidth: '497px'}}
						/>
						{/*<img
							src={calendarImg}
							alt="calendar banner"
							className={styles.itemImg}
							style={{maxWidth: '497px'}}
						/>*/}
					</div>
				</div>

				<div className={styles.item}>
					<div className={styles.leftSide}>
						<ImagePlaceHolder
							placeholder={images.jobInfoImg.placeholder}
							src={images.jobInfoImg.src}
							className={styles.itemImg}
							alt="Job Information"
							style={{maxWidth: '560px'}}
						/>
					</div>

					<div className={styles.rightSide}>
						<h3 className={styles.itemTitle}>Job Information</h3>
						<div className={styles.itemDescription}>
							Get the right information to the
							dispatched team at the field and
							collect crucial <span className={styles.oneLine}>job site </span>
							information. Reduce internal
							backlogs, maintain high
							standards and impress clients.
						</div>
						<ul className={styles.list}>
							<li className={styles.listItem}>Customizable Fields</li>
							<li className={styles.listItem}>Build-in map</li>
							<li className={styles.listItem}>Easy to use</li>
							<li className={styles.listItem}>All-in-one place</li>
						</ul>
						<TitanBtn large onClick={() => { window.location.href = REDIRECT_URL.registration }}>Try now</TitanBtn>
					</div>
				</div>


				<div className={classNames(styles.item, styles.itemRightSideRightAligned)}>
					<div className={styles.leftSide}>
						<h3 className={styles.itemTitle}>Statistics</h3>
						<div className={styles.itemDescription}>
							Get a detailed view of what’s been keeping you and your team busy. Report on quotes, products and services, visits, GPS tracking, time sheets, and expenses.</div>
							<ul className={styles.list}>
								<li className={styles.listItem}>Track Your Profits</li>
								<li className={styles.listItem}>Compare Results</li>
								<li className={styles.listItem}>Set Goals</li>
								<li className={styles.listItem}>Printable Reports</li>
							</ul>
						<TitanBtn large onClick={() => { window.location.href = REDIRECT_URL.registration }}>Try now</TitanBtn>
					</div>

					<div className={classNames(styles.rightSide, styles.rightSideRightAligned)}>
						<ImagePlaceHolder
							placeholder={images.statisticsImg.placeholder}
							src={images.statisticsImg.src}
							className={styles.itemImg}
							alt="Job Information"
							style={{maxWidth: '497px'}}
						/>
					</div>
				</div>

				<div className={styles.item}>
					<div className={classNames(styles.leftSide, styles.leftSideAddPaddingOnMobile)}>
						<ImagePlaceHolder
							placeholder={images.daySetupImg.placeholder}
							src={images.daySetupImg.src}
							className={styles.itemImg}
							alt="Job Information"
							style={{maxWidth: '504px'}}
						/>

					</div>

					<div className={styles.rightSide}>
						<h3 className={styles.itemTitle}>Day Set Up</h3>
						<div className={styles.itemDescription}>
							Your team can clock in and out of jobs and track expenses from <span className={styles.oneLine}>anywhere, so</span> you can prep payroll faster. You have never plan your <span className={styles.oneLine}>working day</span> so easy and clear.</div>
						<ul className={styles.list}>
							<li className={styles.listItem}>Crew Set-up and Management</li>
							<li className={styles.listItem}>Working hours calculator</li>
							<li className={styles.listItem}>Profit and expense tracker</li>
							<li className={styles.listItem}>Printable Reports and Invoices</li>
						</ul>
						<TitanBtn large onClick={() => { window.location.href = REDIRECT_URL.registration }}>Try now</TitanBtn>
					</div>
				</div>

			{/*	<div className={classNames(styles.item, styles.itemRightSideRightAligned)}>
					<div className={styles.leftSide}>
						<h3 className={styles.itemTitle}>Payment</h3>
						<div className={styles.itemDescription}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus amet.
						</div>
						<ul className={styles.list}>
							<li className={styles.listItem}>Lorem ipsum dolor adipiscing elit.</li>
							<li className={styles.listItem}>sit amet, consectetur</li>
							<li className={styles.listItem}>Ut elit tellus amet</li>
							<li className={styles.listItem}>Ut elit tellus amet</li>
						</ul>
						<TitanBtn large onClick={() => { window.location.href = REDIRECT_URL.registration }}>Try now</TitanBtn>
					</div>

					<div className={classNames(styles.rightSide, styles.rightSideRightAligned)} >
						<ImagePlaceHolder
							placeholder={images.statisticsImg.placeholder}
							src={images.statisticsImg.src}
							className={styles.itemImg}
							alt="Job Information"
							style={{maxWidth: '478px'}}
						/>
					</div>
				</div>
*/}

			</Container>
		</section>
	)
}

export default Solution