import React from 'react'
import styles from './AuthForm.module.scss';
import TitanBtn from '../../components/TitanBtn';
import TitanAlert from '../../components/TitanAlert';
import FormInput from '../../components/FormInput';

export default function ResetPasswordForm({submitForm, state, dispatch, setLoginForm}) {
	const isEmailSend = false;
	return (
		<>
			<h1 className={styles.formTitle}>Reset Password</h1>
			<form onSubmit={submitForm}>
				<div className={styles.formWrapContent}>
					{isEmailSend &&
						<div className={styles.textMessageSuccess}>
							We send confirmation letter to you email.<br/>
							Please check in and follow instruction.
						</div>
					}
					<div style={{opacity: isEmailSend ? '0' : '100'}}>
						<div className={styles.textMessage}>Please enter your email address, we will send you a link <br/>
							to reset your password.</div>
						{state.error && state.error.global &&
						<TitanAlert>{state.error.global}</TitanAlert>
						}
						{state.error && state.error.Error &&
						<TitanAlert>{state.error.Error.map(item => <div>{item}</div>)}</TitanAlert>
						}

						<div>
							<FormInput
								name="email"
								onChange={(e) => {
									dispatch({
										type: 'onChange',
										payload: {fieldName: 'email',value: e.target.value}
									})}
								}
								error={state.error.Email}
								value={state.email}
								type="email"
								required
								placeholder="Username or email"
							/>

						</div>
					</div>

				</div>

				<div className={`${styles.formFooter} ${styles.formFooterMargin}`}>
					{isEmailSend
						? <>
							<TitanBtn noShadow large disabled>Send</TitanBtn>
							<div className={styles.footerActions}>
								<a href="/"
										className={styles.link}
										onClick={(e) => {e.preventDefault(); setLoginForm()}}>Log in</a>
							</div>
							</>
						: <TitanBtn large noShadow disabled={state.isFetching}>{state.isFetching ? 'Loading...' : 'Sent'}</TitanBtn>
					}
				</div>

			</form>
		</>

	)
}
