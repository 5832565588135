import React from 'react'
import classNames from 'classnames';
import styles from './TitanBtn.module.scss'

function TitanBtn({large, children, secondary, className: cssClassName, noShadow, ...rest}) {
	const className = classNames({
		[styles.btn]: true,
		[styles.btnLarge]: large,
		[styles.secondary]: secondary,
		[styles.noShadow]: noShadow,
		[cssClassName]: cssClassName
	})
	return (
		<button className={className} {...rest}>{children}</button>
	)
}

export default TitanBtn;