import React, { useState, useEffect } from 'react';
import styles from './Menu.module.scss'
import classNames from 'classnames';

const MenuItem = ({ itemName, id, active }) => {

	const [anchorTarget, setAnchorTarget] = useState(null);

	useEffect(() => {
		setAnchorTarget(document.getElementById(id));
	}, [id]);
	const handleClick = event => {
		event.preventDefault();
		anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};
	return (
		<li className={styles.menuItem}>
			<a href={`#${id}`}
			   onClick={handleClick}

			   className={classNames({[styles.menuItemLink]: true, [styles.menuItemLinkActive]: active})}
				aria-label={`Scroll to ${itemName}`}>
				{itemName}
			</a>
		</li>
	);
};

export default MenuItem;