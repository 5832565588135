import React, {useState} from 'react'
import Container from '../Container/Container';
import styles from './VideoBanner.module.scss';
import {PlayVideo} from "../../components/AuthForm/icons";

function VideoBanner() {
    const [isShowVideo, showVideo] = useState(false);
    return (
        <div id="taHowItWorks">
          <Container>
            <div className={styles.video}>
                <h2 className={styles.video__title}>How it works ?</h2>
                <div className={styles.video__wrapper} id="videoContainer">
                    {!isShowVideo &&
                        <>
                            <img className={styles.video__media} src="https://i.ytimg.com/vi/yzk2M9zXZLw/maxresdefault.jpg" alt='clepis how its work'/>
                            <button className={styles.video__button} aria-label="play video" onClick={() => {
                                showVideo(true)
                            }}>
                                {PlayVideo()}
                            </button>
                        </>
                    }
                    {isShowVideo &&
                        <iframe title="video__media" className={styles.video__media}
                                src="https://www.youtube.com/embed/yzk2M9zXZLw?rel=0&showinfo=0&autoplay=1"/>
                    }
                </div>
            </div>
          </Container>
        </div>
    )
}

export default VideoBanner