import React, {useState, useRef} from 'react'
import classNames from 'classnames'
import Container from '../Container/Container'
import styles from './Bullets.module.scss'
import heroImg from './imgs/hero@2x.webp'
import clockImg from './imgs/clock.svg'
import headphonesImg from './imgs/headphones.svg'
import chartsImg from './imgs/charts.svg'
import checkImg from './imgs/check.svg'
import electroImg from './imgs/electro.svg'
import calendarImg from './imgs/calendar.svg'

const slides = [
	{
		img: clockImg,
		header: 'Day Setup',
		text: 'Flexible, real-time scheduling that gets your team to the right place at the right time.'
	},
	{
		img: chartsImg,
		header: 'Reporting',
		text: 'Valuable reports and insights that paint a clearer picture of where your business is now'
	},
	{
		img: headphonesImg,
		header: 'Product support',
		text: 'Phone, and email support with an average wait time of 36 seconds'
	},
	{
		img: electroImg,
		header: 'Optional add-ons',
		text: 'Boost your revenue with optional line items and packages'
	},
	{
		img: calendarImg,
		header: 'Scheduling',
		text: 'Keep track of important client detail'
	},
	{
		img: checkImg,
		header: 'Text Reminders',
		text: 'Keep clients informed before, during and after work'
	},
]

export function useSwipe(slides) {
	const lastTouch = useRef(0);
	const bulletDomItem = useRef(null);
	const lastMovement = useRef(-80);
	const [transitionDuration, setTransitionDuration] = useState('0.0s');
	const [activeSlideIndex, setSlide] = useState(0);
	const [movement, setMovement] = useState(0);
	// eslint-disable-next-line
	const [lastDelta, setLastDelta] = useState(0);

	function handleTouchStart(e) {
		lastTouch.current = e.nativeEvent.touches[0].clientX;
	}
	function handleTouchMove(e) {
		if(window.innerWidth >= 1024) {
			return
		}
		const delta = lastTouch.current - e.nativeEvent.touches[0].clientX;
		lastTouch.current = e.nativeEvent.touches[0].clientX;
		handleMovement(delta);
	}

	function transitTo(nextSlideIndex) {
		if(window.innerWidth >= 1024) {
			return
		}
		if(activeSlideIndex !== nextSlideIndex) {
			lastMovement.current = movement;
			if(nextSlideIndex === 0) {
				lastMovement.current = -80;
			}
		}

		setTransitionDuration('0.5s');
		setMovement(nextSlideIndex * bulletDomItem.current.clientWidth + 12 * nextSlideIndex);
		setSlide(nextSlideIndex);
	}

	function handleTouchEnd(e) {
		if(window.innerWidth >= 1024) {
			return
		}
		lastTouch.current = 0;
		const slideW = bulletDomItem.current.clientWidth;
		let newSlideIndex;

		if((movement - lastMovement.current) >= slideW / 2) {
			// go right
			newSlideIndex = activeSlideIndex + 1;
		} else {
			// go left
			newSlideIndex = activeSlideIndex - 1;
		}
		if(newSlideIndex >= slides.length) {
			newSlideIndex = slides.length  - 1
		}
		if(newSlideIndex < 0) {
			newSlideIndex = 0
		}
		transitTo(newSlideIndex)
	}
	function handleMovement(delta) {
		if(window.innerWidth >= 1024) {
			return
		}
		let nextMovement = movement + delta;
		if((activeSlideIndex === (slides.length  - 1)) && nextMovement > movement) {
			return
		}
		if (nextMovement < 0) {
			nextMovement = 0;
		}
		setTransitionDuration('0s');
		setMovement(nextMovement);
		setLastDelta(delta);
	}
	return {
		bulletDomItem,
		movement,
		handleTouchStart,
		handleTouchMove,
		handleTouchEnd,
		transitTo,
		activeSlideIndex,
		transitionDuration
	};
}

function Bullets({id}) {
	const {
		bulletDomItem,
		movement,
		handleTouchStart,
		handleTouchMove,
		handleTouchEnd,
		transitTo,
		activeSlideIndex,
		transitionDuration
	} = useSwipe(slides);

	return (
		<section className={styles.wrap} id={id}>
			<Container>
				<div className={styles.colsWrap}>
					<div className={styles.col}>
						<div className={styles.hero}>
							<div>
								<h2 className={styles.heroTitle}>Why Clepis?</h2>
								<div className={styles.heroText}>
									Let’s make this journey together and find how our app can help you manage your day to day business
								</div>
							</div>

							<div className={styles.heroImgWrap}>
								<img src={heroImg} className={styles.heroImg} alt=""/>
							</div>
						</div>
					</div>
					<div className={styles.col2}>
						<div className={styles.sliderWrap}
						     style={{
							     transform: `translateX(${movement * -1}px)`,
							     transitionDuration: transitionDuration,
						     }}
						     onTouchStart={handleTouchStart}
						     onTouchMove={handleTouchMove}
						     onTouchEnd={handleTouchEnd}
						>
							{slides.map(({img, text, header,}) => (
								<div
									ref={bulletDomItem}

									className={styles.bullet}
									key={header}

								>
									<img src={img} className={styles.bulletImg} alt=""/>
									<h4 className={styles.bulletTitle}>{header}</h4>
									<div className={styles.bulletText}>
										{text}
									</div>
								</div>
							))
							}
						</div>

					</div>

					<div className={styles.sliderDots}>
						{slides.map((item, index) =>
							<div key={item.header}
							     onClick={(e) => transitTo(index)}
							     className={
								     classNames({[styles.sliderDotsItem]: true, [styles.sliderDotsItemActive]: index === activeSlideIndex})
							     }
							/>
						)}
					</div>

				</div>

			</Container>
		</section>
	)
}
export default Bullets