import React, {useState} from 'react'
import classNames from 'classnames'
import styles from './ImagePlaceHolder.module.scss'

function ImagePlaceHolder({ placeholder, className, alt, ...rest}) {
	const [isLoaded, setLoaded] = useState(false)
	return <>
		{!isLoaded &&
		<img
			{...rest}
			src={placeholder}
			className={styles.placeHolder}
			alt={alt}
		/>
		}

		<img
			{...rest}
			className={classNames({
				[className]: true,
				[styles.mainImg]: !isLoaded,
				[styles.loaded]: isLoaded
			})}

			onLoad={() => setLoaded(true)}
			alt={alt}
		/>
	</>
}

export default ImagePlaceHolder