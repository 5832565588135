import React from 'react';
import Bullets from './Bullets/Bullets';
import Solution from './Solution/Solution';
import HeroBanner from './HeroBanner/HeroBanner';
//import PriceTable from './PriceTable/PriceTable';
import PriceTableSimple from './PriceTableSimple/PriceTableSimple';
import BusinessesBanner from "./BusinessesBanner/BusinessesBanner";
import ContactForm from "./ContactForm/ContactForm";
//import Slider from './Slider/Slider';

import Footer from './Footer/Footer';
import Menu from './Menu/Menu';
import VideoBanner from "./VideoBanner/VideoBanner";

import styles from './LandingPage.module.scss';

export const REDIRECT_BASE_URL = process.env.REACT_APP_APP_URL;
export const REDIRECT_URL = {
	login: `${REDIRECT_BASE_URL}/login`,
	registration: `${REDIRECT_BASE_URL}/signup`
}

const pageConfig = [
	{
		label: 'Why Clepis?',
		id: 'taHeroBanner',
		component:(props) =>  <HeroBanner {...props} />
	},
	{
		label: 'How it works ?',
		id: 'taHowItWorks',
		component:(props) =>  <VideoBanner {...props} />
	},
	{
		label: 'Price',
		id: 'taPrice',
	//	component: (props) => <PriceTable {...props} />
		component: (props) => <PriceTableSimple {...props} />
	},
	{
		label: 'Features',
		id: 'taBullets',
		component:(props) =>  <Bullets {...props} />
	},
	{
		label: 'Solution',
		id: 'taSolution',
		component: (props) => <Solution {...props} />
	},

	{
		label: 'Businesses',
		id: 'taBusinesses',
		component: (props) => <BusinessesBanner {...props} />
	},
	{
		hideInMenu: true,
		label: 'Contact Us',
		id: 'taContactForm',
		component: (props) => <ContactForm {...props} />
	},
	{
		hideInMenu: true,
		label: 'Footer',
		id: 'taFooter',
		component: (props) => <Footer {...props} />
	}
];

export default function LandingPage() {
	return (
			<div className={styles.wrap}>
				<Menu config={pageConfig.filter(item => !item.hideInMenu).map(item => ({...item}))} />
				{
					pageConfig.map(item => item.component({id: item.id, key: item.id}))
				}
			</div>
	)
}