import React, {useReducer} from 'react'
import { loginUserRequest } from '../../services/'
import LoginForm from './LoginForm'
import RegistrationForm from './RegistrationForm'
import ResetPasswordForm from './ResetPasswordForm'
import styles from './AuthForm.module.scss'
import {LOCAL_STORAGE_KEY} from '../../constants';

export const formTypes = {
	logIn: 'logIn',
	register: 'register',
	resetPassword: 'resetPassword'
};

function reducer(state, action) {
	switch (action.type) {
		case 'onChange':
			return {
				...state,
				error: {},
				[action.payload.fieldName]: action.payload.value
			};

		case 'make-request':
			return { ...state, isFetching: true };
		case 'request-success':
			return { ...state, isFetching: false };
		case 'change-form':
			return {
				...state,
				formTypes: Object.keys(formTypes).reduce((acc, curr) => (
					{
						...acc,
						[formTypes[curr]]: formTypes[curr] === action.payload
					}
				), {})
			};
		case 'request-error':
			return { ...state, isFetching: false, error: parseErrors(action.payload) };
		default:
			throw new Error();
	}
}

const initialState = {
	email: '',
	password: '',
	error: {},
	isFetching: false
};

function parseErrors(err) {
	if(err.json && err.json) {
		return err.json
	}

	else {
		return {
			global: err.toString()
		}
	}
}

export default function AuthForm({formType}) {
	const [state, dispatch] = useReducer(
		reducer,
		{
			...initialState,
			formTypes: Object.keys(formTypes).reduce((acc, curr) => (
				{
					...acc,
					[formTypes[curr]]: formTypes[curr] === formType
				}
			), {})
		}
	);
	function submitLoginForm(e) {
		e.preventDefault();
		dispatch({type: 'make-request'});
		loginUserRequest({email: state.email, password: state.password})
			.then(data => {
				window.localStorage.setItem(LOCAL_STORAGE_KEY, data.json.jwt);
				window.location.reload()
			})
			.catch(err => {
				dispatch({type: 'request-error', payload: err})
			})
	}


	if(state.formTypes[formTypes.logIn]) {
		return (
			<div className={styles.wrap}>
				<LoginForm
					state={state}
					submitForm={submitLoginForm}
					dispatch={dispatch}
					setRegistrationForm={() => dispatch({type: 'change-form', payload: formTypes.register})}
					setResetPasswordForm={() => dispatch({type: 'change-form', payload: formTypes.resetPassword})}
				/>
			</div>
		)
	}
	if(state.formTypes[formTypes.register]) {
		return (
			<div className={styles.wrap}>
				<RegistrationForm
					state={state}
					submitForm={submitLoginForm}
					dispatch={dispatch}
					setLoginForm={() => dispatch({type: 'change-form', payload: formTypes.logIn})}
				/>
			</div>
		)
	}
	if(state.formTypes[formTypes.resetPassword]) {
		return (
			<div className={styles.wrap}>
				<ResetPasswordForm
					state={state}
					submitForm={submitLoginForm}
					dispatch={dispatch}
					setLoginForm={() => dispatch({type: 'change-form', payload: formTypes.logIn})}
				/>
			</div>
		)
	}

}
