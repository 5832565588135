import React from 'react'
import Container from '../Container/Container'
import TitanBtn from '../../components/TitanBtn'
import FormInput from '../../components/FormInput/FormInput'
import styles from './ContactForm.module.scss'

export default function ContactForm({id}) {
	return (
		<section className={styles.wrap} id={id}>
			<Container>
				<div className={styles.formWrap}>
					<h3 className={styles.formTitle}>Any Questions?</h3>
					<form action="">
						<div className={styles.formField}>
							<div className={styles.formFieldItem}>
								<FormInput placeholder="Name" required />
							</div>
							<div className={styles.formFieldItem}>
								<FormInput placeholder="Email" type="email" required />
							</div>
						</div>
						<div>
							<FormInput placeholder="Comments" textarea required />
						</div>
						<div className={styles.formFooter}>
							<TitanBtn large>Send message</TitanBtn>
						</div>
					</form>
				</div>
			</Container>
		</section>
	)
}