import React from 'react'
import styles from './AuthForm.module.scss';
import {AppleIcon, FacebookIcon, GoogleIcon} from './icons';
import TitanBtn from '../../components/TitanBtn';
import TitanAlert from '../../components/TitanAlert';
import FormInput from '../../components/FormInput';

export default function LoginForm({submitForm, state, dispatch, setRegistrationForm, setResetPasswordForm}) {
	return (
		<>
			<h1 className={styles.formTitle}>Log In to Continue</h1>
			<form onSubmit={submitForm}>
				<div className={styles.btnsWrap}>
					<a href="/" className={styles.authBtn}>
						<i className={styles.btnIcon}><GoogleIcon /></i>
						Google
					</a>
					<a href="/" className={styles.authBtn}>
						<i className={styles.btnIcon}><FacebookIcon /></i>
						Facebook
					</a>
					<a href="/" className={styles.authBtn}>
						<i className={styles.btnIcon}><AppleIcon /></i>
						Apple
					</a>
				</div>
				<div className={styles.separator}><span>or</span></div>
				{state.error && state.error.global &&
				<TitanAlert>{state.error.global}</TitanAlert>
				}
				{state.error && state.error.Error &&
				<TitanAlert>{state.error.Error.map(item => <div>{item}</div>)}</TitanAlert>
				}

				<div>
					<FormInput
						name="email"
						onChange={(e) => {
							dispatch({
								type: 'onChange',
								payload: {fieldName: 'email',value: e.target.value}
							})}
						}
						error={state.error.Email}
						value={state.email}
						type="email"
						required
						placeholder="Username or email"
					/>
					<FormInput
						name="password"
						error={state.error.Password}
						onChange={(e) => {
							dispatch({
								type: 'onChange',
								payload: {fieldName: 'password',value: e.target.value}
							})}
						}
						value={state.password}
						type="password"
						required
						placeholder="Password"
					/>
				</div>
				<div className={styles.formFooter}>
					<TitanBtn noShadow large disabled={state.isFetching}>{state.isFetching ? 'Loading...' : 'Login'}</TitanBtn>
					<div className={styles.footerActions}>
						Don't have an account? <a href="/"
						                          className={styles.link}
						                          onClick={(e) => {e.preventDefault(); setRegistrationForm()}}>Sign up</a>
						<div className={styles.footerActionsBottom}>
							<a href="/"
							   className={styles.link}
							   onClick={(e) => {e.preventDefault(); setResetPasswordForm()}}
							>Forgot password</a>
						</div>
					</div>
				</div>

			</form>
		</>

	)
}
