import React from 'react'

function TermsOfUse() {
  return (
    <div>
      <h1>Terms Of Use</h1>
    </div>
  )
}

export default TermsOfUse