import React from 'react'
import classNames from 'classnames';
import styles from './FormInput.module.scss'

export default function FormInput({type = "text", error, textarea, ...rest}) {
	if(textarea) {
		return (
			<div>
				<textarea
					cols="30"
					rows="7"
					className={classNames({[styles.input]: true, [styles.textarea]: true, [styles.error]: error})}
					{...rest}
				/>
			</div>
			)
	}
	return (<div>
		<input type={type}
		       className={classNames({[styles.input]: true, [styles.error]: error})}
		       {...rest}
		       />
		       {error &&
			       <div className={styles.errorsWrap}>
				       {error.map(item => <div className={styles.errorText}>{item}</div>)}
			       </div>
		       }
	</div>)
}