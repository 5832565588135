import React from 'react';
import { render } from 'react-dom'
import {
	BrowserRouter as Router,
	Switch,
} from 'react-router-dom'
import {Route} from 'react-router'
import './index.css';
import LandingPage from './landingPage/';
import reportWebVitals from './reportWebVitals';
import PrivacyPolicy from './components/LegalStuff/PrivacyPolicy';
import TermsOfUse from './components/LegalStuff/TermsOfUse';

const urlParams = new URLSearchParams(window.location.search);

if(urlParams.get('src') === 'qrcode') {
	window.gtag("event", "from_qr_code", {
		data: urlParams.get('data')
	});
}

render(
	<React.StrictMode>
		<Router>
			<Switch>
				<Route
					path="/"
					exact
					component={LandingPage}
				/>
				<Route
					path="/privacy-policy"
					component={PrivacyPolicy}
				/>
				<Route
					path="/terms-of-use"
					component={TermsOfUse}
				/>
				<Route component={LandingPage}/>
			</Switch>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

function sendToGoogleAnalytics({name, delta, value, id}) {
	// Assumes the global `gtag()` function exists, see:
	// https://developers.google.com/analytics/devguides/collection/ga4
	window.gtag('event', name, {
		// Built-in params:
		value: delta, // Use `delta` so the value can be summed.
		// Custom params:
		metric_id: id, // Needed to aggregate events.
		metric_value: value, // Optional.
		metric_delta: delta, // Optional.

		// OPTIONAL: any additional params or debug info here.
		// See: https://web.dev/debug-web-vitals-in-the-field/
		// metric_rating: 'good' | 'ni' | 'poor',
		// debug_info: '...',
		// ...
	});
}

reportWebVitals(console.log)
reportWebVitals(sendToGoogleAnalytics);
