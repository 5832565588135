import React from 'react'
import Container from '../Container/Container'
import {IconFaceBook, IconInstagram, IconTwitter} from './Icons'
import styles from './Footer.module.scss'

function Footer({id}) {
	return (
		<footer id={id}>
			<Container>
				<div className={styles.wrap}>
					<div className={styles.colLarge}>
						<h4 className={styles.footerTitle}>Clepis App</h4>
						<div className={styles.footerDescription}>
							Easy Solution to Grow Your Business. <br/>
							Manage your business with Clepis. <br/>
							Save money and time
						</div>
					</div>
					<div className={styles.col}>
						<h4 className={styles.footerTitle}>Contact Us</h4>
						<a className={styles.labelLink} href="tel:+18556981885">+1 (855) 698-1885</a>
						<a className={styles.labelLink} href="mailto:contact@clepis.com">contact@clepis.com</a>
					</div>
					<div className={styles.col}>
						<h4 className={styles.footerTitle}>Connect</h4>
						<a className={styles.iconLink} href="/"><IconFaceBook /></a>
						<a className={styles.iconLink} href="/"><IconInstagram /></a>
						<a className={styles.iconLink} href="/"><IconTwitter /></a>
					</div>
				</div>

			</Container>

		</footer>
	)
}

export default Footer