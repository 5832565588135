import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from './TitanModal.module.scss'

const modalRoot = document.getElementById('modal-root');

function TitanModal({children, onClose}) {
	const el = document.createElement('div');
	useEffect(() => {
		modalRoot.appendChild(el);
		document.body.classList.add(styles.hideOverflow)
		return () => {
			document.body.classList.remove(styles.hideOverflow)
			modalRoot.removeChild(el);
		}
	}, );
	return ReactDOM.createPortal(
		<div className={styles.modal}>
			<div className={styles.overlay} />
			<button className={styles.closeBtn} onClick={() => onClose()}><CloseIcon /></button>
			<div className={styles.modalContent}>
				{children}
			</div>
		</div>,
		el
	)
}

function areEqual(prevProps, nextProps) {
	return prevProps.children !== nextProps.children
}

export default React.memo(TitanModal, areEqual)


function CloseIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<g fill="#000" fillRule="nonzero">
					<path d="M1838.01 84c.569 0 1.043-.19 1.422-.568.379-.38.568-.853.568-1.421 0-.569-.19-1.043-.568-1.422l-8.59-8.589 8.59-8.59c.379-.378.568-.852.568-1.42 0-.569-.19-1.043-.568-1.422-.38-.379-.853-.568-1.421-.568-.569 0-1.043.19-1.422.568l-8.589 8.59-8.59-8.59c-.378-.379-.852-.568-1.42-.568-.569 0-1.043.19-1.422.568-.379.38-.568.853-.568 1.421 0 .569.19 1.043.568 1.422l8.59 8.589-8.59 8.59c-.379.378-.568.852-.568 1.42 0 .569.19 1.043.568 1.422.38.379.853.568 1.421.568.569 0 1.043-.19 1.422-.568l8.589-8.59 8.59 8.59c.378.379.852.568 1.42.568z" transform="translate(-1816.000000, -60.000000)"/>
				</g>
			</g>
		</svg>
	)
}