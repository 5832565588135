import React from 'react'
import logo from './img/logo.svg'
import billing from './img/billing.svg'
import calendar from './img/calendar.svg'
import client from './img/client.svg'
import customize from './img/customize.svg'
import invoice from './img/invoice.svg'
import reporting from './img/reporting.svg'
import secure from './img/secure.svg'
import time from './img/time.svg'

import styles from './PriceTableSimple.module.scss'
import Container from '../Container/Container';
import {REDIRECT_URL} from '../LandingPage';
import TitanBtn from '../../components/TitanBtn';

function PriceTableSimple({id}) {
	return <section id={id}>
		<Container>
			<div className={styles.wrap}>
				<div className={styles.logoWrap}>
					<img src={logo} alt="logo"/>
				</div>
				<h4 className={styles.header}>Everything that your business need.</h4>
				<h3 className={styles.promoHeader}>30 Days for free</h3>
				<div className={styles.content}>
					<div className={styles.contentItem}>
						<img className={styles.contentItem__icon} src={calendar} alt="Scheduling"/>
						<div className={styles.contentItemTitle}>Scheduling</div>
						<div className={styles.contentItemText}>Your team always knows where to go. Customers know when to expect them.</div>
					</div>
					<div className={styles.contentItem}>
						<img className={styles.contentItem__icon} src={time} alt="Hours tracker"/>
						<div className={styles.contentItemTitle}>Hours tracker</div>
						<div className={styles.contentItemText}>
							Ensure accurate billing by keeping track of your hours. Approve time cards from any place.
						</div>
					</div>
					<div className={styles.contentItem}>
						<img className={styles.contentItem__icon} src={reporting} alt="Reporting"/>
						<div className={styles.contentItemTitle}>Reporting</div>
						<div className={styles.contentItemText}>
							Profit and loss scheduling, inventory status, new business follow-up, and much more.
						</div>
					</div>
					<div className={styles.contentItem}>
						<img className={styles.contentItem__icon} src={client} alt="Client Management"/>
						<div className={styles.contentItemTitle}>Client Management</div>
						<div className={styles.contentItemText}>
							Make your working process with leads and consumers clear.
						</div>
					</div>
					<div className={styles.contentItem}>
						<img className={styles.contentItem__icon} src={billing} alt="Billing"/>
						<div className={styles.contentItemTitle}>Billing</div>
						<div className={styles.contentItemText}>
							Generate electronic billing. Scan checks for immediate deposit.
						</div>
					</div>
					<div className={styles.contentItem}>
						<img className={styles.contentItem__icon} src={secure} alt="Secure Cloud-Based Platform"/>
						<div className={styles.contentItemTitle}>Secure Cloud-Based Platform</div>
						<div className={styles.contentItemText}>
							Clepis is password protected on a secure platform. Protect your business operations.
						</div>
					</div>
					<div className={styles.contentItem}>
						<img className={styles.contentItem__icon} src={invoice} alt="Invoicing"/>
						<div className={styles.contentItemTitle}>Invoicing</div>
						<div className={styles.contentItemText}>
							Easy, flexible Invoice creation.
						</div>
					</div>
					<div className={styles.contentItem}>
						<img className={styles.contentItem__icon} src={customize} alt="Customizable Job Interface"/>
						<div className={styles.contentItemTitle}>Customizable Job Interface</div>
						<div className={styles.contentItemText}>
							Customize interface especially for your business.
						</div>
					</div>
				</div>
				<div className={styles.footer}>
					<TitanBtn
						large
						noShadow
						className={styles.button}
						onClick={(e) => {
							e.preventDefault();
							window.location.href = REDIRECT_URL.registration
						}}

					>Start free trial
					</TitanBtn>

				</div>
				<div className={styles.footerPrice}>
					$0.00 for the first 30 days, after $99.00 Monthly. Automatically renewal, cancel anytime.
				</div>
			</div>
		</Container>
	</section>
}
/*
<div className={styles.content}>
	<div className={styles.contentItem}>
		<div className={styles.contentItemTitle}>Scheduling</div>
		<div className={styles.contentItemText}>Easy, flexible scheduling. Your team always knows where to go. Customers know when to expect them.  Reassign jobs in the field.</div>
	</div>
	<div className={styles.contentItem}>
		<div className={styles.contentItemTitle}>Reports</div>
		<div className={styles.contentItemText}>
			Generate accurate reports that let you let you see where your business is: profit and loss scheduling, inventory status, new business follow-up, and much more.
		</div>
	</div>
	<div className={styles.contentItem}>
		<div className={styles.contentItemTitle}>Communications</div>
		<div className={styles.contentItemText}>
			Communication is key to a well-run business. Send messages to customers. Communicate with employees at job sites. You are always in touch.</div>
	</div>
	<div className={styles.contentItem}>
		<div className={styles.contentItemTitle}>Invoicing/Payment</div>
		<div className={styles.contentItemText}>
			Generate electronic billing in the field. Scan checks for immediate deposit. Accept other forms of payment.
		</div>
	</div>
	<div className={styles.contentItem}>
		<div className={styles.contentItemTitle}>Hours Tracker</div>
		<div className={styles.contentItemText}>
			Ensure accurate billing by keeping track of your hours. Compare hours at various job sites. Approve time cards from any place.
		</div>
	</div>
	<div className={styles.contentItem}>
		<div className={styles.contentItemTitle}>Support</div>
		<div className={styles.contentItemText}>
			Clepis is designed for easy use. No steep learning curve. You are immediately productive. If you do encounter problems, we have dedicated product specialists available. </div>
	</div>
	<div className={styles.contentItem}>
		<div className={styles.contentItemTitle}>Secure Platform</div>
		<div className={styles.contentItemText}>
			Clepis is password protected on a secure platform so that you can protect your business operations. You choose the level of access for each employee.
		</div>
	</div>
</div>*/

export default PriceTableSimple